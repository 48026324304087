// Dependencies
import React from "react";
import { motion } from "framer-motion";

const LoadingAnimation = ({ children }) => {
    return (
        <motion.div
            initial={{
                y: 10,
                opacity: 0
            }}
            animate={{
                y: 0,
                opacity: 1
            }}
            transition={{
                type: "tween",
                delay: 0.1,
                ease: "easeInOut"
            }}
        >
            {children}
        </motion.div>
    );
}

export default LoadingAnimation;