// Dependencies
import React from 'react';
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from 'gatsby';
import styled from "@emotion/styled";
import { RichText } from "prismic-reactjs";

// Styles
import colors from "styles/colors";
import dimensions from "styles/dimensions";

// Components
import Layout from "components/Layout";
import LoadingAnimation from "components/LoadingAnimation";
import BackButton from "components/ui/BackButton";


// Holds project Hero image
const ProjectHeroContainer = styled("div")`
    background: ${colors.grey200};
    display: flex;
    justify-content: center;
    margin-top: 3.5em;
    margin-bottom: 3.5em;
    
    img {
        width: 100%;
        padding: 2em 6em 2em 6em;

        @media (max-width: ${dimensions.maxwidthTablet}px) {
            padding: 2em 2em 2em 2em;
        }

        @media (max-width: ${dimensions.maxwidthMobile}px) {
            padding: 2em 1em 2em 1em;
        }

        @media (min-width: ${dimensions.minwidthXLscreen}px) {
            padding: 2em 8em 2em 8em;
        }
    }
`

// Title (h2)
const ProjectTitle = styled("div")`
    width: 80%;
    margin: 0 auto;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        width: 100%;
    }
`

// Description (p)
const ProjectDesc = styled("div")`
    width: 80%;
    margin: 0 auto;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        width: 100%;
    }

    p {
        font-size: 1.25em;
        line-height: 1.4em;

        margin-top: 0.7em;
        margin-bottom: 0.7em;

        color: ${colors.grey700};
    }
`

// Text
const ProjectBody = styled("div")`
    width: 80%;
    margin: 0 auto;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        width: 100%;
    }

    // Inline images
    .block-img {
        line-height: 0;
        margin-bottom: -0.5em;

        img {
            width: 100%;
        }
    }

    // Bullet lists
    ul {
        margin-top: -1em;
        margin-bottom: 0em;

        @media (max-width: ${dimensions.maxwidthTablet}px) {
            margin-left: 0em;
            padding-left: 1.5em;
        }
    }

    // Bullet point items
    li {
        margin: 0.4em 0;

        // Bullet point symbol color
        &::marker {
            color: ${colors.grey800};
        }
    }

    /* Styling links */
    a {
        text-decoration: none;
        transition: all 125ms ease-in-out; 
        color: ${colors.bqblue500};

        &:hover {
            cursor: pointer;
            transition: all 125ms ease-in-out;
            color: ${colors.bqblue600};    
            background-color: ${colors.bqblue200};
        }
    }
`

const Project = ({ project, meta }) => (
    <>
        <Helmet
            title={`${project.project_title[0].text}`}
            titleTemplate={`%s – ${meta.title}`}
            meta={[
                {
                    name: `description`,
                    content: `${project.project_preview_description.text}`,
                },
                {
                    property: `og:title`,
                    content: `${project.project_title[0].text} – ${meta.title}`,
                },
                {
                    property: `og:description`,
                    content: `${project.project_preview_description.text}`,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: meta.author,
                },
                {
                    name: `twitter:title`,
                    content: `${project.project_title[0].text} – ${meta.title}`,
                },
                {
                    name: `twitter:description`,
                    content: `${project.project_preview_description.text}`,
                },
            ].concat(meta)}
        />
        <Layout>
            <LoadingAnimation>
                <ProjectTitle>
                    {RichText.render(project.project_title)}
                </ProjectTitle>

                <ProjectDesc>
                    {RichText.render(project.project_preview_description)}
                </ProjectDesc>
                
                {project.project_hero_image && (
                    <ProjectHeroContainer>
                        <img src={project.project_hero_image.url} alt="Project banner" />
                    </ProjectHeroContainer>
                )}

                <ProjectBody>
                    
                    {RichText.render(project.project_description)}

                    <BackButton toPage={"/"} />

                </ProjectBody>
            </LoadingAnimation>
        </Layout>
    </>
);

export default ({ data }) => {    
    const projectContent = data.prismic.allProjects.edges[0].node;
    const meta = data.site.siteMetadata;
    
    return (
        <Project project={projectContent} meta={meta}/>
    );
}

// Ensure project is an object
Project.propTypes = {
    project: PropTypes.object.isRequired,
};

// GraphQL query
export const query = graphql`
    query ProjectQuery($uid: String) {
        prismic {
            allProjects(uid: $uid) {
                edges {
                    node {
                        project_title
                        project_preview_description
                        project_preview_thumbnail
                        project_category
                        project_post_date
                        project_hero_image
                        project_description
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`